<template>
   <div :class='{"light":$root.d,"dark":!$root.d}'>
       <div class="row justify-content-center my-5" >
           <div class="col-lg-6 col-12">
            <div>
          <!-- <span class='float-right link txGold' style="margin-right:13px;" @click='cls'> <b>x</b></span> -->
      <div class="row justify-content-center px-3 m-0" v-if='$store.state.action == "signup"'>
          <div class="col-12 p-0 m-0">
            <h5 class='txGold'>CREATE YOUR ACCOUNT</h5>
          </div>
          <div class="col-12" v-if='errors.length'>
            <p class="text-danger" v-for='(error, index) in errors' :key='index'>{{error}}</p>
          </div>
          <div class="col-12" v-if='successMessage.length'>
            <p class="text-success" >{{successMessage}}</p>
          </div>
          <div class="col-12 col-md-6 px-1">
            <div class="form-group">
              <!-- <label class='m-0' for="first_name">First Name</label> -->
                    <input-comp-small fors='signup'
                    :error="(firstName.length > 1) ? false : true"
                    :errorbox="(formSubmitted) ? ((firstName.length > 1) ? false : true) : false"
                    @input='updatefirstName' name='firstname' :value='firstName' type='text' placeholder='Enter your First Name'></input-comp-small>
            </div>
          </div>
          <div class="col-12 col-md-6 px-1">
            <div class="form-group">
              <!-- <label class='m-0' for="last_name">Last Name</label> -->
                    <input-comp-small fors='signup'
                    :error="(lastName.length > 1) ? false : true"
                    :errorbox="(formSubmitted) ? ((lastName.length > 1) ? false : true) : false"
                    @input='updatelastName' name='lastname' :value='lastName' type='text' placeholder='Enter your Last Name'></input-comp-small>
            </div>
          </div>
          <div class="col-12 col-md-6 px-1">
            <div class="form-group">
              <!-- <label class='m-0' for="email">Email</label> -->
                    <input-comp-small fors='signup'
                    :error="(emailValidated) ? false : true"
                    :errorbox="(formSubmitted) ? ((emailValidated) ? false : true) : false"
                    @input='updateemail' name='email' :value='email' type='email' placeholder='Enter your Email'></input-comp-small>
            </div>
          </div>
          <div class="col-12 col-md-6 px-1">
            <div class="form-group">
              <!-- <label class='m-0' for="username">Username</label> -->
                    <input-comp-small
                    :error="(username && !usernameError) ? false : true"
                    :errorbox="(formSubmitted) ? ((username && !usernameError) ? false : true) : false"
                    fors='signup' @input='updateUsername' name='username' :value='username' type='text' placeholder='Enter your Username'></input-comp-small>
            </div>
          </div>

          <div class="col-12 px-1">
            <div class="form-group" :class="{'border border-danger' :(formSubmitted && !numberValidated)}" style="border-radius:8px;">
              <!-- <label class='m-0' for="phone">Phonenumber</label> -->
              <VuePhoneNumberInput @update="validateNumber" v-model="number" :dark="$root.d" />
            </div>
          </div>

          
          <div class="col-12 col-md-6 px-1">
            <div class="form-group">
              <!-- <label class='m-0' for="password">Password</label> -->
                    <input-comp-small 
                    :error="(password.length > 5 && password == confirmPassword) ? false : true" :value="password"
                    :errorbox="(formSubmitted) ? ((password.length > 5 && password == confirmPassword) ? false : true) : false"
                    fors='signup' @input='updatePassword' name='password' type='password' placeholder='Enter a Password'></input-comp-small>
            </div>
          </div>
          <div class="col-12 col-md-6 px-1">
            <div class="form-group">
              <!-- <label class='m-0' for="password">Confirm Password</label> -->
                    <input-comp-small 
                    :error="(password.length > 5 && password == confirmPassword) ? false : true" :value="confirmPassword"
                    :errorbox="(formSubmitted) ? ((password.length > 5 && password == confirmPassword) ? false : true) : false"
                    fors='signup' @input='updateconfirmPassword' name='confirm password' type='password' placeholder='Confirm Password'></input-comp-small>
            </div>
          </div>
          <div class="col-12">
            <p class="text-danger" v-if="emailVerifiedNot">
              <span v-if="mailSentProm">
              Verification Email Has Been, Please Check your Inbox. <br>Verify your email and then try again. 

              </span>
              <span v-else>
Email Address is not Verified, please check your inbox or <span @click="sendMailProm" class="link txGold px-2">Click Here</span>
            to Send Email Again
              </span>
            
            
            </p>
              <!-- <button class="btn-reg d-block mx-auto" style="max-width:300px;padding:.8rem 1.5rem !important;" @click='showReferralLink'>Show Referral Link</button> -->

          </div>
          
          <div class="col-12">
            <!-- <div class="row"></div> -->
            <!-- <div class="cap-c">
                <div class="g-recaptcha" id='captcha' data-callback="myCallback" :data-theme="theme" :data-sitekey="rcapt_sig_key">
                </div>
            </div> -->
          </div>
          <div class="col-12 col-md-7 px-3" v-if='!$store.state.isLoading'>
              <button class="btn btn-primary d-block mx-auto" style="max-width:300px;padding:.8rem 1.5rem !important;" @click='signup'>SIGN UP</button>
            </div>
          <div class="col-12 col-md-7 px-3" v-if='!$store.state.isLoading'>
            <p class='text-center'>Already have an account?
              <button class="btn btn-link txGold px-1" @click='$store.state.action = "signin"'>
                Click Here
              </button>
            </p>              
          </div>
          
          
        </div>
        <div class="row justify-content-center pl-4" v-else-if='$store.state.action == "signin"'>
          <div class="col-12 m-0 p-0">
            <h5 class='txGold'>SIGN INTO CLAIMELLY</h5>
          </div>
          
          <div class="col-12 px-1">
            <div class="form-group ">
              <label class='m-0' for="username">Username/Email</label>
                    <input-comp-small fors='signin' 
                    :error="(username.length > 1) ? false : true"
                    :errorbox="(formSubmitted) ? ((lastName.length > 1) ? false : true) : false" :value="username"
                    type='text' fill='auto' auto='on' @input='updateUsername' name='username' placeholder='Enter your Username/Email'></input-comp-small>
            </div>
          </div>
          <div class="col-12 px-1">
            <div class="form-group">
              <label class='m-0' for="password">Password</label>
                    <input-comp-small 
                    :error="(password.length > 5) ? false : true"
                    :errorbox="(formSubmitted) ? ((lastName.length > 5) ? false : true) : false" 
                    :value="password" @login='signin' fors='signin' @input='updatePassword' fill='auto' auto='on' name='password' type='password' placeholder='Enter a Password'></input-comp-small>
            </div>
            <!-- <p class='txGold link' @click='$store.state.action = "forgot"'> <b>Forgot Password? Click here to recover </b> </p> -->
          </div>
         
          <div class="col-12" v-if='twoFa'>
            <input type="text" name="" id="" v-model='twoFaCode' placeholder='Enter your 2FA code' class="form-control">
            <p class="text-danger" v-if='tfaError'>{{tfaError}}</p>
            <button class="btn-reg d-block mx-auto" style="max-width:300px;padding:.8rem 1.5rem !important;"  @click='twoFaVerify'> Submit </button>
          </div>
   
          <div class="col-12" v-if='tempUser == "asdsadsa"'>
            
            <div class="row">
              <div class="col-12">
                <p class="text-danger" v-if='!mailSent'>
              Your Email Address Is Not Verified, Check inobx/spam for confirmation email or
              <span class="click txGold" @click='sendMail'>Click Here</span> to send email again
            </p>
            <p class="text-success" v-else>
              Email verification mail has been sent to your email address check your inbox/spam
            </p>
              </div>
            </div>
          
          </div>
          <div class="col-12 col-md-7 px-3" v-if='!$store.state.isLoading'>
              <button class="btn btn-primary d-block mx-auto" style="max-width:300px;padding:.8rem 1.5rem !important;" @click='signin'>SIGN IN</button>
            </div>
          <div class="col-12 col-md-7 px-3" v-if='!$store.state.isLoading'>
            <p class='text-center'>Need to create an account?
              <button class="btn btn-link txGold px-1" @click='$store.state.action = "signup"'>
                Click Here
              </button>
            </p>              
          </div>
        </div>
        <div class="row pl-4" v-else>
          <div class="col-12">
            <h5 class='txGold'>Forgot Password</h5>
          </div>
          
          <div class="col-12 px-1">
            <div class="form-group">
              <label class='m-0' for="username">If you have forgotten your password please enter your username and specify a new password.  You will then be sent a reset code to your registered email address to complete this password reset</label>
                    <input-comp-small
                    :error="(username.length > 1 ) ? false : true" 
                    fors='signup' :disabled='codeSent' :value='username' type='text' @input='updateUsername' placeholder='Username'></input-comp-small>
            </div>
          </div>

          <div class="col-12 px-1">
            <div class="form-group">
              <label class='m-0' for="username">New Password</label>
                    <input-comp-small :error="(newPassword.length > 5 && newPassword == verifyNewPassword) ? false : true"
                     fors='signup' type='text' :value="newPassword" @input='updateNewPassword' placeholder='New Password'></input-comp-small>
            </div>
          </div>

          <div class="col-12 px-1">
            <div class="form-group">
              <label class='m-0' for="username">Verify New Password</label>
                    <input-comp-small
                    :error="(newPassword.length > 5 && newPassword == verifyNewPassword) ? false : true" :value="verifyNewPassword"
                    fors='signup' type='text' @input='updateVerifyNewPassword' placeholder='Verify New Password'></input-comp-small>
            </div>
          </div>

           <div class="col-12 px-1">
            <div class="form-group">
              <label class='m-0' for="username">Password Verification Code (Check Inbox Or Spam or click 
                <span class='link txGold' @click='forgot'> <b>Here</b> </span> to resend code)
                 </label>
                    <input-comp-small
                    :error="(passwordVerifyCode.length  == 6 ) ? false : true" :value="passwordVerifyCode"
                    fors='signup' type='text' @input='updatePasswordVerifyCode' placeholder='Password Verification Code'></input-comp-small>
            </div>
          </div>
          
          <div class="col-12 col-md-6 px-3 mb-3" v-if='!$store.state.isLoading'>
              <button class="btn btn-primary d-block mx-auto" style="max-width:300px;padding:.8rem 1.5rem !important;" v-if='!codeSent' @click='forgot'> Send Password Reset Code </button>
              <button class="btn button-gold-outline" v-else @click='updatePasswordChange'> Change Password </button>
          </div>
          <div class="col-12 col-md-6 px-3 mb-3" v-if='!$store.state.isLoading'>
              <button class="btn btn-primary d-block mx-auto" style="max-width:300px;padding:.8rem 1.5rem !important;"  @click='$store.state.action = "signin"'>SIGN IN</button>
            </div>
          
        </div>
        <div class="text-center p-4 txGold"  v-if='$store.state.isLoading'>
          <!-- <b-spinner class='txGold' label="Text Centered"></b-spinner> -->
          </div>
          <p class="text-danger" v-if='error'> <b> {{errorMsg}} </b> </p>
          
        
  </div>
           </div>
           
       </div>

            <div class="mx-width-extra">
                <Footer></Footer>

            </div>


        </div>
</template>

<script>

import InputCompSmall from '@/components/InputCompSmall.vue'
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
import {mapState} from 'vuex'
import Swal from 'sweetalert2'
var validator = require("email-validator");

import { Profanity, ProfanityOptions } from '@2toad/profanity';

const options = new ProfanityOptions();
options.wholeWord = false;

const profanity = new Profanity(options);
    const Footer = () => import("../components/Footer.vue");
    const SmallCard = () => import("../components/SmallCard.vue");
    const WhitePaperComp = () => import("../components/WhitePaperComp.vue");

export default {
    components:{Footer,SmallCard,WhitePaperComp, VuePhoneNumberInput,InputCompSmall,},
    mounted(){
        this.loadCaptcha()
    },
    watch:{
      '$store.state.action' (value) {
        this.ready = false;
        setTimeout(() => {
          this.ready = true;          
        }, 500);
        this.errors = [];
        this.errorMsg = '';
        this.tfaError = '';
        // setTimeout(() => {
        //     let cont = document.getElementById('captcha')
        //   if(value == "signup"){
        //     console.log('captcha container is', cont)
        //     grecaptcha.render(
        //     cont,
        //     {"sitekey": this.rcapt_sig_key}
        //     )
        //     window.myCallback = this.imNotARobot
        //   }
          
        // }, 2000);
      },
      email(email){
        this.emailValidated = validator.validate(email);
      },
      username(value){
        this.usernameError = false
        let dec = profanity.exists(this.username);
        if (dec || value.length < 2) {
          this.usernameError = true
        }  
      }
    },
    data() {
        return {
            formSubmitted:false,
          tfaError:null,
          IdDoc:null,
          SlfDoc: null,
           IdDocB64:null,
          SlfDocB64: null,
          imgQr:null,
      twoFaCode:null,    
      twoFaUser : null,
      twoFa : false,
      email:'',
      number:'',
      address:'',
      city:'',
      dob:null,
      country:"",
      postCode:'',
      errors:[],
      firstName:'',
      lastName:'',
      error:false,
      errorMsg:null,
      codeSent:false,
      username:'',
      password:'',
      confirmPassword:'',
      passwordVerifyCode:'',newPassword:'',verifyNewPassword:'',
      capStatus:false,
      rcapt_sig_key:'6LeQR0ceAAAAADXQ0t9OuPM8-l6GSEtmtU9zg_p3',
      residence:'',
      dType:1,
      walletAddress:null,
      emailVerifiedNot:false,
      promUser:{},
      numberStatus:{},
      mailSent:false,
      mailSentProm:false,
      tempUser:null,
      ready:false,
      successMessage:"",
      usernameError:false,
      emailValidated:false,
      live_google_recpachid:null,
        }
    },
    methods:{
        loadCaptcha(){
    //   console.log('loading captcha')
    //   setTimeout(() => {
    //     let cont = document.getElementById('captcha')
    //     console.log('captcha element',cont)
    //   grecaptcha.render(
    //         cont,
    //         {"sitekey": this.rcapt_sig_key}
    //         )
    //         window.myCallback = this.imNotARobot
    //   }, 500);
      
    //   setTimeout(() => {
    //     let elm = document.getElementById('captcha').childNodes
    //     console.log('captcha element',elm)

    //     console.log(elm[0])
    //   }, 1000);
    },
      async showReferralLink(){
        let validEmail = false
        var filter = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
        validEmail = (!filter.test(this.email)) ? false : true;
        let accounts =  await ethereum.request({ method: 'eth_requestAccounts' });
        this.walletAddress = accounts[0]
        if (this.walletAddress && validEmail) {
          let dec = await this.$store.dispatch("fetchRegisterInterest", {email: this.email})
          console.log(dec)
          if (dec.user) {
            if (dec.user.emailVerified) {
              this.userRefLink = location.origin+'/referral/'+this.walletAddress
            }else{
              this.promUser = dec.user
              this.emailVerifiedNot = true
            }
          }else{
           this.$root.Toast.fire({icon: "warning",title: 'No Valid Registration Foound'}) 
          }
        }else{
         (!validEmail) ? this.$root.Toast.fire({icon: "warning",title: 'Need a Valid Email address'})
         : this.$root.Toast.fire({icon: "warning",title: 'Wallet Address is required'})
        }
      },
      validateNumber(value){
        this.numberStatus = value
      },
      checkInput(value){
        console.log("number validation", value)
      },
      async twoFaVerify() {
        this.$store.isLoading = true
       this.tfaError = null
       let dec = await this.$store.dispatch('verifyTfaLogin', {code: this.twoFaCode,user:this.twoFaUser, login: true})
       console.log('dec for submit tfa', dec);
       if(dec.status == 1){
          //   this.$bvToast.toast('2FA Athentication Activated', {
          //   title: '2FA Athentication',
          //   variant: 'success',
          //   solid: true
          // })
       }else{
         if(dec.error !== null)
         {
           if(dec.error == -1) this.tfaError = "Key Was Entered Too Late"
           if(dec.error == 1) this.tfaError = "Key Was ENtered Too Early"
         }else{
           this.tfaError = "Failed to authenticate the key"
         }
     }
      },
      async sendMail(user){
        let dec = await this.$store.dispatch("sendMail", {email:user.email, id:user._id, prom:false})
        if(dec.status){
          this.mailSent = true
        }
      },
      async sendMailProm(){
        let dec = await this.$store.dispatch("sendMail", {email:this.promUser, id:this.promUser._id, prom:true})
        if(dec.status){
          this.mailSentProm = true
        }
      },
      cls() {
        // alert('asd')
        this.$emit("cls")
      },
      async updatePasswordChange() {
        if(this.newPassword.length > 5 && this.newPassword === this.verifyNewPassword && this.passwordVerifyCode.length ==6){
          this.$store.isLoading = true
          let dec = await this.$store.dispatch('updatePassword',
           {username:this.username, password: this.newPassword, code:this.passwordVerifyCode}
           )
           console.log(dec)
            this.$store.isLoading = false;
            if(dec.status)this.codeSent = true
            this.$root.Toast.fire({
            icon: dec.icon,
            title: dec.message
          })
          this.codeSent = false
          this.newPassword = '';
          this.verifyNewPassword = '';
          this.passwordVerifyCode = ''
          this.username = '';
          this.$store.action = "signin"
        }else{
          let message = '';
          if(this.newPassword.length < 6) message = 'Password Length must be at least 6 characters'
          if(this.newPassword.length !== this.verifyNewPassword) message = 'Password Must match with Verify Password'
          if(this.passwordVerifyCode.length !== 6) message = 'Password Recovery Code must b 6 characters'
           this.$root.Toast.fire({
            icon: "warning",
            title: message
          })
        }
      },
      async forgot(){
        if(this.username.length){
          this.$store.isLoading = true
          let dec = await this.$store.dispatch('sendPasswordResetCode', this.username)
            this.$store.isLoading = false;
            if(dec.status)this.codeSent = true
            this.$root.Toast.fire({
            icon: dec.icon,
            title: dec.message
          })
        }
      },
      imNotARobot(data) {
        this.capStatus = true
      },
      async convImageOne(event){
        this.IdDocB64 = null 
        let file = event.target.files[0]
        const reader = new FileReader()
        let rawImg;
        reader.onloadend = () => {
           var b64 = reader.result.replace(/^data:.+;base64,/, '');
          console.log(b64);
          rawImg = reader.result;
          this.IdDocB64 = b64
        }
        reader.readAsDataURL(file);
        
        },

      async convImageTwo(event){
        this.SlfDocB64 = null 
        let file = event.target.files[0]
        const reader = new FileReader()
        let rawImg;
        reader.onloadend = () => {
           var b64 = reader.result.replace(/^data:.+;base64,/, '');

          rawImg = reader.result;
          this.SlfDocB64 = b64
        }
        reader.readAsDataURL(file);
        },

      async validate() {
        this.usernameError = false

        this.errors = [];
        if(this.firstName.length < 1 || this.lastName.length < 1)
        {
        this.errors.push('First name & Last name are required')
        }
        if(this.username.length < 2)
        {
        this.errors.push('Username must be atleast 5 characters')
        this.usernameError = true
        }
        if(!this.numberStatus.isValid)
        {
        this.errors.push('Please Provide a valid Phonenumber')
        } 
        // var filter = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
        (!this.emailValidated) ? this.errors.push("Please provide a valid email") : '';
        // if(this.SlfDocB64 == null || this.IdDocB64 == null) this.errors.push("Please Provide Both Images")
        if(this.password.length < 6 || this.password !== this.confirmPassword) 
        this.errors.push("Password must be at least 6 characters and must match with Confirm Password")
        // if(!this.capStatus) this.errors.push("Please Complete Captcha")


      },
      updatePasswordVerifyCode(value) {this.passwordVerifyCode = value},
      updateNewPassword(value) {this.newPassword = value},
      updateVerifyNewPassword(value) {this.verifyNewPassword = value},
      updateAddress(value){this.address = value},
      updatePostCode(value){this.postCode = value},
      updateCity(value){this.city = value},
      updateCountry(value){this.country = value},
      updatefirstName(value){this.firstName = value},

      updatelastName(value){this.lastName = value},
      updateemail(value){this.email = value},
      updateconfirmPassword(value){this.confirmPassword = value},
      updateUsername(value){this.username = value},
      updatePassword(value){this.password = value},
      async matchProm(email){
        return await this.$store.dispatch("checkRefe", {email})
        
      },
      async signup() {
        this.errors = []
        this.successMessage = "";

        await this.validate()
        
          let dec = await this.$store.dispatch('registerClient',{
            username:this.username,
            firstName:this.firstName,
            lastName:this.lastName,
            password:this.password,
            email:this.email,
            phone:this.number,
          });
          if (dec.status) {
            this.successMessage = "Registration Successfull";
            this.$store.state.action = "signin"
            // this.username = 
            Swal.fire({icon:"success",title: `Registration Successfull`})
          } else {
            this.errors.push(dec.message)
          }
      },
      async signin() {
        this.formSubmitted = true
        this.error = false;
        this.errorMsg = null;
        if(this.username.length > 4 && this.password.length > 4)
        {
        this.$store.state.isLoading = true
          let dec = await this.$store.dispatch('login', {username: this.username, password: this.password})
          console.log(dec, this.$store.state.userType)
          if(dec.status == 1){
           this.$router.push({path:"/dashboard"})
           this.formSubmitted = false
           this.$store.state.isLoading = false

          }
          else if (dec.status == 2)
          {
            this.formSubmitted = false
            this.$bvModal.hide("modal-register");
            this.$store.state.isLoading = false
            this.tempUser = dec.user
            const swal = Swal.mixin({
            customClass: {
              confirmButton: 'btn btn-gold',
              denyButton: 'btn btn-link txGold'
            },
            buttonsStyling: false
          })
            swal.fire({
              toast:false,
              timer:150000,
              position:'center',
            html: `
            <h4 class='txDark py-3'>
            Your Email Address has not been verified.<br><br>
            Please check your Inbox (also check Promotions / Spam) for confirmation email.<br><br>
            Please click "Resend Email" to send the confirmation email again if required.<br><br>
            OR Click "Support" to contact support if necessary<h4>
            `,
            showDenyButton: true,
            showCloseButton: true,
            showConfirmButton: true,
            showCancelButton: false,
            confirmButtonText: 'Resend Email',
            denyButtonText: `Support`,
            CancelButtonText: `Close`,
          }).then(async (result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
              await this.sendMail(this.tempUser)
              this.$root.Toast.fire('Email Verification Link Sent', '', 'success')
            } else if (result.isDenied) {
              window.location.href='mailto:support@claimelly'
              // this.$root.Toast.fire('Contacting to Support', '', 'info')
            }
          })

          }
          else if (dec.status == 3)
          {
            //close modal
            //load twoFA popup
             this.$store.state.isLoading = false
            this.twoFaUser = dec.user
            this.$bvModal.hide("modal-register")
          Swal.fire({
          title: 'Submit your Authenticator Code', input: 'number',
          inputAttributes: { autocapitalize: 'off'},
          showCancelButton: true, confirmButtonText: 'Submit',
          showDenyButton: true, denyButtonText: 'Lost Authenticator App? Contact Support',
          showLoaderOnConfirm: true,
          preConfirm: async (code) => {
            return await this.$store.dispatch('verifyTfaLogin', {code,user:this.twoFaUser, login: true})

              // return await this.$store.dispatch('verifyTfa', {mode,code, tfaStatus:(newValue) ? "Running" : "Active"})
          },
          allowOutsideClick: () => !Swal.isLoading()
          })
          .then(async (res) => {
            console.log("verifyTfa",res)
            if(res.isConfirmed)
            {
              if (res.value.status == 1) 
              {
                location.replace('/')           
              // Swal.fire({icon:"success",title: `2FA Status Updated`})
              }
              else
              {
                if(res.value.error !== undefined)
                {
                  if(res.value.error == -1) this.tfaError = "Key Was Entered Too Late"
                  if(res.value.error == 1) this.tfaError = "Key Was Entered Too Early"
                  if(res.value.error == null) this.tfaError = "Invalid Code Provided, please try again"
                }else{
                  this.tfaError = "Failed to authenticate the key"
                }
                  this.$root.Toast.fire({ icon: 'error', title: this.tfaError})
                // this.$store.dispatch('update2faStatus', !newValue)          
              this.updateTfaValue()
              }
            }else{
              if (res.isDenied) {
                await this.$store.dispatch("requestTfaReset", this.twoFaUser._id);
              Swal.fire({icon:"success",
              title: `2FA Request Sent <br <br> 
              An update will be provided via your registered email address for claimelly`})

              }
              this.updateTfaValue()
            }
              // this.updateTfaValue()
          })
           
          }
          else{
            this.$store.state.isLoading = false
            this.error = true;
            this.errorMsg = dec.message;
          }
        }else{
          this.errorMsg ='Please enter valid username and password'
          this.error = true;
        }
      }
    }
}
</script>

<style scoped>

</style>